import React, { useState} from 'react';
import './gallery.css';
import firstGallery from '../pages/images/gallery-1.png';
import secondGallery from '../pages/images/gallery-2.png';
import thirdGallery from '../pages/images/gallery-3.png';
import fourthGallery from '../pages/images/gallery-4.png';
import fifthGallery from '../pages/images/gallery-5.png';
import sixthGallery from '../pages/images/gallery-6.png';
import seventhGallery from '../pages/images/gallery-7.png';
import opgsSolar from '../pages/images/oga-wordmark-white.svg';
import left from'../pages/images/left.svg';
import right from '../pages/images/right.svg';


function Gallery() {
  const [src, setSrc] = useState(firstGallery);

  const imageArray = [firstGallery, secondGallery, thirdGallery, fourthGallery, fifthGallery, sixthGallery, seventhGallery];

  const handleChange = (direction) => {
    const index = imageArray.findIndex(el => el === src);

    if (direction === 'forward' && src !== imageArray[6]){
      setSrc(imageArray[index+1]);
    } else if(direction === 'forward') {
      setSrc(imageArray[0]);
    } else if (direction === 'back' && src !== imageArray[0]){
      setSrc(imageArray[index-1]);
    } else if(direction === 'back') {
      setSrc(imageArray[6]);
    }

  }

  return (
    <div className='gallery' id='gallery'>
      <div className='container'>

        <div className='carosoul'>
          <img className='buttons' src={left} onClick={(e) => handleChange('back', e)} />
          <img src={src} alt='Gallery Carosoul' />
          <img className='buttons' src={right} onClick={(e) => handleChange('forward', e)} />
        </div>

  
        <div className='footer'>
          <img src={opgsSolar} alt='Oga solar wordmark logo' />
        </div>

      </div>
    </div>
  )
}

export default Gallery