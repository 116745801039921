import Home from "./pages/home";
import Navbar from "./pages/navbar";
import About from "./pages/about";
import Products from "./pages/products";
import Services from "./pages/services";
import ContactUs from "./pages/contactUs";
import Gallery from "./pages/gallery";

import React, { Component } from 'react';
import './App.css';

class App extends Component {
  render() {
    return (
       <div className="main-div">
        <Navbar />
        <Home  />
        <About />
        <Products />
        <Services />
        <ContactUs />
        <Gallery />
      
       </div>
       
   );
  }
}

export default App;
