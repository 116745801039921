import React from 'react'
import './home.css';
import wordmark from './images/oga-wordmark.svg';

export default function Home() {
  return (
    <div style={{ height: "100vh" }} className='home' id='home'>
      <div className='container'>

        <div className='first-grid'>
          <div className='text-box'>

          </div>
          <div className='image-box'>
            <div className='text'>
              <div>
                <h1>Let us bring light to your life <br />
                  and to your business. <br />
                  We work with the environment.
                </h1>
              </div>

              <div><p>Letsatsi ke <span>matla</span></p></div>
            </div>

            <div>
              <img src={wordmark} alt='Oga solar wordmark logo' />
            </div>
          </div>
        </div>



        <div className='second-grid'>

        </div>




      </div>

      <div className='mobile-container'>
        <div className='mobile-text'>
          <div>
            <h1>Let's bring light to your life
              and to your business. <br />
              We work with the environment.
            </h1>
          </div>

          <div><p>Letsatsi ke <span>matla</span></p></div>
        </div>
        <div className='mobile-image'>
          <img src={wordmark} alt='Oga solar wordmark logo' />
        </div>
      </div>
    </div>
  )
}
