import React, {useState} from 'react';
import {Link} from 'react-scroll';
import './navbar.css';

function Navbar() {

    const [classState, setClassState] = useState('header');
    const wordEnum = {
        'home': 'header',
        'about': 'aboutState',
        'products': 'productsState',
        'services': 'servicesState',
        'contactUs': 'contactUsState',
    }
    
    const handleChange = (state) => {

    setClassState(wordEnum[state]);
      }
  return (
    <div className={classState}>
        <nav className='navbar'>

          <ul className='nav-menu'>
              <li className='nav-item'>
                  <Link to="home" spy={true} smooth={true} offset={50} duration={500} onSetActive={handleChange}>Home</Link>
              </li>
              <li className='nav-item'>
                  <Link to="about" spy={true} smooth={true} offset={50} duration={500} onSetActive={handleChange}>About us</Link>
              </li>
              <li className='nav-item'>
                  <Link to="products" spy={true} smooth={true} offset={50} duration={500} onSetActive={handleChange}>Products</Link>
              </li>
              <li className='nav-item'>
                  <Link to="services" spy={true} smooth={true} offset={50} duration={500} onSetActive={handleChange}>Services</Link>
              </li>
              <li className='nav-item'>
                  <Link to="contactUs" spy={true} smooth={true} offset={50} duration={500} onSetActive={handleChange}>Contact Us</Link>
              </li>
              <li className='nav-item'>
                  <Link to="gallery" spy={true} smooth={true} offset={50} duration={500} onSetActive={handleChange}>Gallery</Link>
              </li>
          </ul>
        </nav>
    </div>
  )
}

export default Navbar