import React from 'react';
import './about.css';

export default function About() {
  return (
    <div className='about' id='about'>
      <div className='bottom-image'></div>
      <div className='container'>

      <div className='workers'>

      <div className='aboutus'>
        <p className='mantana-bold'>OGA Solar is a 100% Black owned and run company <br />with a B-BEE level 1 contributer status.</p>
        <p className='mantana-underline'>The inspiration for our company comes from three interrelated factors.</p>

        <p className='large-paragraph'>South Africa has been experiencing <span>load shedding since 2007</span>. In the <br />
        intervening years, the problem had become worse and represents the systemic <br />
        challenges imbedded within the country's utility company. Consequently, we <br />
        have reached a point where alternative energy sources are the <span>only realistic <br />
        hope</span> for the majority of the citizens.
        </p>

        <p className='large-paragraph'>Our country is endowed with being one of the sunniest places in the world. <br />
        With an avergae of <span>10.2 hours of sunlight a day,</span> South Africa is sitting pretty at <br />
        position 5 of top ten countries in the world. Curiously, during the months of <br />
        November, December and January we lead the pack and occupy the <span>no.1 spot <br />
        as the sunniest place in the globe.</span>
        </p>

        <p className='large-paragraph'>The size and complexity of our economy, against the backdrop of our natural <br />
        resources mix, makes our country an ideal place to take advantage of the <br />
        many natural factors in mitigation for the <span>transition to renewable energy.</span>
        </p>

      </div>

        <div className='pop-up'>
          <div className='director'>
            
            <div className='image'>
      
            </div>
            <div className='text-box'>
              <h3>Our Director</h3>
              <p>Ntja Mapheelle is our director. He is <br />
              an experienced and passionate <br />
              entrepreneur with an experience of <br />
              nearly 20 years. Above all, Ntja is a <br />
              leader with the capacity to lead the <br />
              compant and build it into a <br />
              formidable competitr in the <br />
              market.
              </p>
            </div>
          </div>

          <div className='our-team'>
            <div className='text-box'>
              <h3>our team</h3>
              <p>OGA solar prides itself in recruiting <br />
              qualified and experienced workers <br />
              with all the requisite skills of our <br />
              trade. The diversity in our team is the <br />
              biggest enabler of our capabilities <br />
              and capacity to deliver seamless <br />
              installation and advise to our <br />
              growing list of clients.
              </p>
              <p>Our technicians are PV Green Card <br /> 
              Accredited.
              </p>
            </div>
            <div className='image'>
            
              </div> 
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}
