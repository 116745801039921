import React from 'react'
import './products.css';

function Products() {
  return (
    <div className='products' id='products'>
        <div className='container'>

            <div className='title'>
                <h1>Our Products</h1>
                <p>Our team of experts is at hand to provide the<br/> following services:</p>
            </div>

            <div className='product-images'>

                <div className='product-card-1'>
                
                </div>

                <div className='product-card-2'>
                    </div>

                <div className='product-card-3'>
                 
                </div>

                <div className='product-card-4'>
                    
                </div>

            </div>

            <div className='closer'>
                <p>Our installers are trained and experienced to work with all roof<br /> surfaces, shapes and types and with <br /> single phase and three phase power systems.</p>
            </div>
        </div>
    </div>
  )
}

export default Products;