import React, { useState } from 'react';
import axios from 'axios';
import './contactUs.css';

function ContactUs() {
  const [inputs, setInputs] = useState({});
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}));

  }

  const handleSubmit = async (event) => {
    await axios.post("https://oga-solar.africa/email-send/contact-us", inputs);
  }

  return (
    <div className='contactUs' id='contactUs'>
      <div className='container'>
        <div className='title'>
          <h1 className='left-heading'>We can't wait</h1>
          <h1 className='right-heading'>to work with you!</h1>

          <br></br>

          <p className='underline'>Please contact us for a no obligation quotation and free advice.</p>
          <p className='contact'>whatsapp: +27 73 756 8733 <br /> email: info@ogasolar.africa</p>

          <br />
          <p className='contact'>Or simply drop us a message or your number here, <br />so we can keep in touch.</p>
        </div>
        <div className='form-group'>
        <form className='form' onSubmit={handleSubmit}>
            <label className='label'>Name
            <input 
              type="name" 
              name="name" 
              value={inputs.name || ""} 
              onChange={handleChange}
            />
            </label >
            <label className='label'>Email
              <input 
                type="email" 
                name="email" 
                value={inputs.email || ""} 
                onChange={handleChange}
              />
              </label>
            
              <label className='label'>Message
            <textarea 
              type="text" 
              name="message" 
              rows="7"
              value={inputs.message || ""} 
              onChange={handleChange}
            />
            <input type="submit" />
            </label>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ContactUs