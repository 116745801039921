import React from 'react'
import './services.css';

function Services() {
  return (
    <div  style = {{height:"100vh"}} className='services' id='services'>
        <div className='container'>

            <div className='large-image'>

                <h1>Our Services</h1>
            </div>

            <div className='text-box'>
                <h3>Our team of experts is at hand to provide the following services:</h3>
                <ul>
                    <li> On site assessment of property and its needs</li>
                    <li> Providing quotations</li>
                    <li> Installations of new back-up and solar systems</li>
                    <li> Installations of solar geysers</li>
                    <li> Decommissioning of old system</li>
                    <li> Fault finding</li>
                    <li> General electrical works</li>
                </ul>
            </div>



        </div>
    </div>
  )
}

export default Services